.subscription-products-closeContainer {
  padding: 20px 0px;
  display: flex;
  justify-content: end;
}
.subscription-products-closeContainer svg {
  width: 25px;
  height: 25px;
}
.subscription-products-root {
  padding: 15px;
}

.subscription-products-root .MuiTableCell-body {
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: medium !important;
}

.subscription-products-root .MuiTableCell-head {
  color: rgba(0, 0, 0, 0.87) !important;
}

@media (max-width: 800px) {
  .subscription-products-closeContainer svg {
    width: 18px;
    height: 18px;
  }
  .subscription-products-root {
    padding: 0px;
  }
}
