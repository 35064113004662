.errors-container-root {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.errors-loading-skeleton-container {
  display: grid;
  grid-template-columns: 1fr;
}
