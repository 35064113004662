.order-confirmation-modal-root {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}
.order-confirmation-modal-customerDetails {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}
.order-confirmation-modal-info {
  display: grid;
  grid-template-columns: 1fr;
}
.order-confirmation-modal-lineitems {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}
.selected-payment-container {
  display: flex;
  gap: 30px;
}

.selected-payment-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.order-confirmation-modal-name {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

.order-confirmation-modal-meta {
  margin: 10px 0px;
  color: #8a8d8f;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
}

.order-confirmation-modal-meta:last-child {
  margin-bottom: 0px;
}
.order-confirmation-modal-variants {
  display: grid;
  grid-template-columns: 1fr;
}
