.autoship-root {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
}
.autoship-header-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 30px;
}

.autoship-counter-container{
  display: flex;
  justify-content: flex-end;
}