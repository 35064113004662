.delayed-order-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.edit-delayed-order-paper {
  height: 90vh;
  max-height: 95vh;
  width: 95vw;
  overflow-y: auto;
  background-color: #fefefe;
  margin: 0px;
  padding: 5px !important;
}

.show-border-outilne {
  border: 1px solid black
}

.show-border-outilne-l-r {
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.show-border-outilne-t-b-l {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
}

.show-border-outilne-t-r-b {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}

.show-border-outilne-r {
  border-right: 1px solid black;
}

.shopify-details-container {
  margin: 5px;
}

.action-button-container {
  display: flex;
  justify-content: space-evenly;
  padding: 5px 0px;
  background-color: #27293d !important;
}

#action-button-menu .MuiMenu-paper {
  background-color: #27293d !important;
}

#action-button-menu li div{
  align-items: flex-start !important;
}

.action-container {
  margin: 5px;
}

.no-margin-padding {
  margin: 0px !important;
  padding: 0px !important;
}

.robotui-speech-bubble {
	position: relative;
	background: #27293d;
  border: 1px solid #27293d;
	border-radius: .4em;
  margin: 5px;
  padding: 5px;
  color: white;
}

.robotui-speech-bubble:after {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 0.875em solid transparent;
	border-right-color: #27293d;
	border-left: 0;
	margin-top: -0.875em;
	margin-left: -0.875em;
}

.nzpost-speech-bubble {
	position: relative;
	background: #bd0042;
  border: 1px solid #bd0042;
	border-radius: .4em;
  margin: 5px;
  padding: 5px;
  color: white;
}

.nzpost-speech-bubble:after {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 0.875em solid transparent;
	border-right-color: #bd0042;
	border-left: 0;
	margin-top: -0.875em;
	margin-left: -0.875em;
}

.comment-speech-bubble {
	position: relative;
	background: #00bd0d;
	border-radius: .4em;
  margin: 5px;
  padding: 5px;
  color: white;
}

.comment-speech-bubble:after {
	content: '';
	position: absolute;
	right: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 0.875em solid transparent;
	border-left-color: #00bd0d;
	border-right: 0;
	margin-top: -0.875em;
	margin-right: -0.875em;
}

.email-speech-bubble {
	position: relative;
	background: #4a94a8;
  border: 1px solid #4a94a8;
	border-radius: .4em;
  margin: 5px;
  padding: 5px;
  color: white;
}

.email-speech-bubble:after {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 0.875em solid transparent;
	border-right-color: #4a94a8;
	border-left: 0;
	margin-top: -0.875em;
	margin-left: -0.875em;
}

.sticky {
  // position: -webkit-sticky; /* Safari */
  // position: sticky;
  // // bottom: 0px;
  // margin-top: auto;
  // margin-bottom: 0px;
  place-self: flex-end;
  z-index: 9999;
}


.delayed-order-grid-height {
  max-height: 650px;
}

.image-panel {
  overflow: auto !important;
  height: 230px !important;
}

.image-panel-grid {
  max-height: 500px;
}

.full-grid-length {
  max-height: 780px;
  height: 780px;
}

.mute-form-control {
  color: white !important;
}

.mute-form-control .PrivateSwitchBase-root-10 {
  padding: 5px !important;
}

.center {
  display: flex;
  justify-content: center;
}

.delayed-orders-home-container {
  background-color: #27293d !important;
  color: white !important;
  height: auto !important;
  // margin: 10px !important;
}

.delays-table-container {
  height: calc(100vh - 300px); 
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .image-panel {
      overflow: auto !important;
      height: 145px !important;
    }
    .image-panel-grid {
      max-height: 350px !important;
    }
    .full-grid-length {
      max-height: 650px;
      height: 650px;
    }
}