.subscription-frequency-subscriptionOptions {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}
.subscription-frequency-confirmContainer {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 600px;
  color: var(--grey-phillipine);
}

.subscription-frequency-confirmContainer h4 {
  font-weight: normal;
}

.subscription-frequency-confirmContainer p {
  font-size: 14px;
  line-height: 20px;
}

.subscription-frequency-requiredFields {
  text-align: right;
}

.subscription-frequency-actionContainer {
  margin-top: 56px;
  justify-content: end;
  display: flex;
  gap: 35px;
}

@media (max-width: 550px) {
  .subscription-frequency-actionContainer {
    flex-direction: column;
  }

  .subscription-frequency-confirmContainer {
    padding: 16px;
    max-height: 90vh;
    overflow: auto;
  }
}
