#packing-screen-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.packing-screen-container {
  display : grid;
  grid-template-columns: 2fr 4fr 2fr;
  height: 100%;
  overflow-y: auto;
  width: 100%;
  gap: 20px;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 10px;
}

.packing-screen-section-container-container {
  height: 100%;
  min-height: 0;
}

.packing-screen-section-container {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.packing-screen-section-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.packing-screen-captioned {
  display: flex;
  flex-direction: column;
}

.packing-screen-captioned-grid,
.packing-screen-captioned-grid-big {
  font-size: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
}

.packing-screen-captioned-grid div,
.packing-screen-captioned-grid-big-item,
.packing-screen-captioned-item {
  background: #FCF8F2;
  width: min-content;
  text-wrap: nowrap;
  padding: 5px;
}

.packing-screen-captioned-grid-big {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.packing-screen-captioned-grid-big-inner {
  padding-left: 10px;
  background:#FCF8F2;
  display: flex;
  justify-content: start;
  flex-direction: column;
  font-size: 20px;
}

.packing-screen-list {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  max-height: 66vh;
  overflow-y: auto;
  gap: 10px;
}

.packing-screen-list-item {
  background: #FCF8F2;
  padding: 14px;
  border-radius: 4px;
}
.packing-screen-list-item[state="selected"] {
  border: 1px solid #FFA500;
}

.packing-screen-list-item-title {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
}

.packing-screen-modal-status-badge-small {
  font-size: 10px !important;
  height: min-content;
  padding: 5px !important;
  border-radius: 5px !important;
}

.packing-screen-current-order-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.packing-screen-current-order-title-buttons {
  display: flex;
  gap: 10px;
}