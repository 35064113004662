.confirmContainer {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 600px;
}

.confirmContainer h4 {
  font-weight: normal;
}

.confirmContainer p {
  font-size: 14px;
  line-height: 20px;
}
.actionContainer {
  margin-top: 10px;
  display: flex;
  gap: 16px;
}

@media (max-width: 550px) {
  .actionContainer {
    flex-direction: column;
  }

  .confirmContainer {
    padding: 16px;
    max-height: 90vh;
    overflow: auto;
  }
}
