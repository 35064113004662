.customer-details-card-root {
}
.customer-details-card-meta{
    font-size: 12px !important;
}
.customer-details-card-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
}
.customer-details-card-loyalty{
    width: 100%;
}
.no-customer-msg{
    text-align: center;
    font-size: 12px !important;
}

.customer-details-card-name {
    cursor: pointer;
    text-decoration: underline;
}