.referral-root {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
}
.referral-header-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 30px;
}

.referral-counter-container{
  display: flex;
  justify-content: flex-end;
}