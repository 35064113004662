.business-direct-paper {
  background-color: #27293d !important;
  color: white !important;
  height: auto !important;
  margin: 10px !important;
}

.business-direct-form {
  margin: auto !important;
  width: 80% !important;
  // border: 1px solid white !important;
  padding: 10px 10px 20px 10px !important;
}

.copy-to-shipping-button {
  background-color: #3f51b5 !important;
  color: white !important;
}

.business-direct-form .MuiSelect-icon {
  color: white;
}

.business-direct-header {
  margin: auto !important;
  padding: 10px !important;
  text-align: center !important;
  margin-left: 15% !important;
}

.business-direct-form .MuiFormLabel-root {
  color: white !important;
  border-color: white !important;
}

.business-direct-form .MuiInputBase-input {
  color: white !important;
  border-color: white !important;
}

.business-direct-form .MuiOutlinedInput-root {
  color: white !important;
  border-color: white !important;
}

.business-direct-form .Mui-focused {
  color: white !important;
  border-color: white !important;
}

.business-direct-sku-table {
  color: white !important;
  border-color: white !important;
}

.bd-table tr:nth-child(even){
  background-color: #525464;
}

.create-edit-company-details-container {
  background-color: #27293d !important;
  color: white !important;
  height: auto !important;
  margin: 10px !important;
}

.business-direct-acordian {
  width: 800px;
  margin-top: 20px;

  /* ----------- iPad 1, 2, Mini and Air ----------- */
  /* Portrait */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  }

  /* Landscape */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    width: 400px;
  }

  /* ----------- iPad 3, 4 and Pro 9.7" ----------- */
  /* Portrait */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  }

  /* Landscape */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    width: 400px;
  }

  /* ----------- iPad Pro 10.5" ----------- */
  /* Portrait */
  /* Declare the same value for min- and max-width to avoid colliding with desktops */
  /* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
  @media only screen and (min-device-width: 834px) and (max-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  }

  /* Landscape */
  /* Declare the same value for min- and max-width to avoid colliding with desktops */
  /* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
  @media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    width: 400px;
  }

  /* ----------- iPad Pro 12.9" ----------- */
  /* Portrait */
  /* Declare the same value for min- and max-width to avoid colliding with desktops */
  /* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    width: 400px;
  }

  /* Landscape */
  /* Declare the same value for min- and max-width to avoid colliding with desktops */
  /* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
  @media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    width: 400px;
  }
}
