.container-div {
  padding: 10px;
}

.paper-style {
  // width: "100%";
  background-color: #27293d !important;
  color: white !important;
}

.visuallyHidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20px;
  width: 1px;
}

.MuiTableCell-head {
  color: white !important;
  font-size: 1.1vw !important;
  padding: 2px !important;
}

.MuiTableCell-body {
  color: white !important;
  font-size: 1.1vw !important;
  padding: 2px !important;
}

.hr-table-style {
  border-color: #737373;
  margin-top: -10px;
}

.button-completed {
  background-color: #006600 !important;
  color: white !important;
}

.button-processing {
  background-color: #b35900 !important;
  color: white !important;
}

.button-packing {
  background-color: #009900 !important;
  color: white !important;
}

.button-fulfilled {
  background-color: purple !important;
  color: white !important;
}

.button-patrick {
  background-color: #903749 !important;
  color: white !important;
}

.document-image {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
  max-width: 100%;
  max-height: 100%;
}

.order-table-search-bar {
  display: flex !important;
  margin-bottom: 15px !important;
}

.order-table-search-bar .MuiInputBase-root {
  color: white !important;
}

.order-table-search-bar .MuiFormLabel-root {
  color: white !important;
}

.chunk-separator-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.chunk-separator-title {
  display: flex;
  align-items: center;
  gap: 30px;
}

.chunk-separator-body {
  display: flex;
  gap: 10px;
  justify-content: end;
  flex: 1;
  margin-right: 10px; 
}


//--------- loading animation ------------
@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.dot {
  height: 20px;
  width: 20px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
}

.loading-dots span {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  margin: 2px;
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

.table-container {
  height: calc(100vh - 240px); //-130px
}

.MuiTableCell-stickyHeader {
  background-color: #27293d !important;
}
//---- PDF Viewer-----
$iPhoneXR: 896px;
$iPhoneSE: 667px;
$iPad: 1024px;
$iPadAir: 1180px;

.pdfViewer canvas {
  width: 100%;

  @media screen and (min-width: 915px) {
    width: auto !important;
    max-height: calc(100vh - 36px);
  }
}

.pdfViewer > div.container {
  @media screen and (min-width: 900px) {
    max-width: auto !important;
  }
}

.canvasCss {
  height: auto;

  @media screen and (min-width: 900px) {
    text-align: center;
  }

  @media (max-height: $iPhoneXR) and (orientation: portrait) {
    margin-top: 50px;
  }
}

.pdfViewer {
  padding: 15px;

  @media (max-height: $iPhoneXR) and (orientation: portrait) {
    padding: 5px;
  }

  @media (max-width: $iPhoneXR) and (orientation: landscape) {
    padding: 10px;
  }
}

.table-body {
  overflow-y: auto;
}

.navbarWrapper {
  position: fixed;
  bottom: 100px;
  left: 30%;
  width: 100%;
}

.navButton {
  color: white;
  background-color: black;
  margin-left: 2px;
  margin-right: 2px;
  border-radius: 20%;
  font-size: 0.7rem !important;
}

.navPageIndicator {
  margin: 2px;
  font-size: 15px !important;
  padding: 8px 3px 8px 3px !important;
  vertical-align: super !important;
  border-radius: 10% !important;
}

.edit-order-paper {
  height: 80vh;
  max-height: 85vh;
  width: 85vw;
  overflow-y: auto;
  background-color: #fefefe;
}

.edit-order-item-text {
  font-weight: bold !important;
  font-size: 1.8em !important;
  text-align: center;
}

.edit-order-item-total-text {
  font-weight: bold !important;
  font-size: 1.8em !important;
  text-align: center;
  color: green !important;
}

.edit-order-item-total-header {
  font-weight: bold !important;
  font-size: 1.2em !important;
  text-align: center;
}

.edit-order-item-item-title {
  display: block;
  margin: 0px auto !important;
  font-size: 1rem !important;
}

.failed-to-create-message {
  position: absolute;
  right: 100px;
  color: red;
}

.label-button {
  margin: 0px 10px !important;
  background-color: black !important;
  color: white !important;
}

.item-compost-container {
  background-color: #704214;
  margin-top: 2px;
  margin-bottom: 2px;
  text-align: center;
}

.item-plastic-container {
  background-color: black;
  margin-top: 2px;
  margin-bottom: 2px;
  text-align: center;
}

.item-return-ticket-container {
  background-color: white;
  margin-top: 2px;
  margin-bottom: 2px;
  text-align: center;
  color: black;
}

.item-misc-container {
  background-color: darkgray;
  margin-top: 2px;
  margin-bottom: 2px;
  text-align: center;
  color: black;
}

.flavour-dot {
  height: 2em;
  width: 2em;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.flavour-P5 {
  background-color: blue;
}

.flavour-P7 {
  background-color: #bbb;
}

.flavour-P9 {
  background-color: darkred;
}

.flavour-P10 {
  background-color: black;
  border: white;
}

.flavour-P12 {
  background-color: purple;
}

.flavour-PDC {
  background-color: #bbb;
}

.flavour-PCA {
  background-color: rgb(243, 154, 71);
}

.flavour-C5 {
  background-color: #bbb;
}

.flavour-C7 {
  background-color: #bbb;
}

.flavour-C9 {
  background-color: #bbb;
}

.flavour-P10 {
  background-color: #bbb;
}

.flavour-C12 {
  background-color: #bbb;
}

.flavour-C14 {
    background-color: #bbb;
}

.flavour-CDC {
  background-color: #bbb;
}

.flavour-RT {
  display: none;
}

//iPad Portrait
@media screen and (max-width: 770px) and (orientation: portrait) {
  .navbarWrapper {
    position: relative;
    bottom: 0px !important;
    width: 100% !important;
    display: flex;
    left: 0px !important;
  }

  .navbarWrapper .column {
    display: flex !important;
    flex-basis: unset !important;
    flex-grow: 1 !important;
    flex-shrink: unset !important;
    padding: unset !important;
  }

  .navbarWrapper .column .button {
    font-size: 1rem !important;
  }

  .failed-to-create-message {
    right: 85px;
  }

  .total-packing-time-text {
    font-size: small !important;
    @media (max-height: $iPhoneXR) and (orientation: portrait) {
      font-size: 0.55em !important;
    }
    @media (max-width: $iPhoneXR) and (orientation: landscape) {
      font-size: 0.6em !important;
    }
  }

  .handpack-text {
    color: white !important;
    align-self: center;
    @media (max-height: $iPhoneXR) and (orientation: portrait) {
      visibility: hidden !important;
    }
  }

  .edit-order-paper {
    height: 80vh;
    max-height: 85vh;
    width: 95vw;
    overflow-y: auto;
    background-color: #fefefe;
  }
}

//iPad Landscape
@media screen and (max-width: 1030px) and (orientation: landscape) {
  .navbarWrapper {
    position: relative;
    bottom: 0px !important;
    width: 100% !important;
    left: 25% !important;
    display: flex !important;
  }
  .navbarWrapper .column {
    flex-basis: unset !important;
    flex-grow: unset !important;
    flex-shrink: unset !important;
    padding: unset !important;
    display: block !important;
  }

  .navbarWrapper .column .button {
    font-size: 0.7rem !important;
  }
}

//iPhone Portrait
@media screen and (max-width: 380px) and (orientation: portrait) {
  .navbarWrapper {
    position: relative;
    bottom: 0px !important;
    width: 100% !important;
  }
  .navbarWrapper .column {
    flex-basis: unset !important;
    flex-grow: unset !important;
    flex-shrink: unset !important;
    padding: unset !important;
    display: block !important;
  }

  .navbarWrapper .column .button {
    font-size: 0.7rem !important;
  }

  .edit-order-paper {
    width: 92vw;
  }
  .edit-order-modal {
    //width: 100%;
  }

  .edit-order-item-text {
    font-size: 1.5em !important;
  }

  .edit-order-item-item-title {
    font-size: 0.7rem !important;
  }

  .failed-to-create-message {
    right: 30px;
  }
}

//iPhone Landscape
@media screen and (max-width: 815px) and (orientation: landscape) {
  .navbarWrapper {
    position: fixed;
    bottom: 30px;
    width: 100%;
    left: 30%;
    display: flex;
  }
}

.MuiTableRow-root.Mui-selected {
  background-color: #133913 !important;
}

.stop-watch {
  color: white;
  position: absolute;
  top: 30px;
  right: 30px;
}

.mobile-table-header {
  @media (max-height: $iPhoneXR) and (orientation: portrait) {
    font-size: 0.8em !important;
  }
  @media (max-width: $iPhoneXR) and (orientation: landscape) {
    font-size: 0.9em !important;
  }
}

.table-header-grid-items {
  align-self: center !important;
}

.scan-out {
  align-self: flex-end;
}

.courier-provider {
  font-size: 9px;
  text-align: center;
  margin-bottom: -3px !important;
}

.label-button {
  background-color: unset !important;
  height: 21px !important;
}

.courier-button-div {
  cursor: pointer;
  // width: 40px;
}

#edit-order-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

#edit-order-tabs-bar {
  @media (max-height: $iPhoneXR) and (orientation: portrait) {
    width: 250px;
  }
  @media (max-width: $iPhoneXR) and (orientation: landscape) {
    width: 550px;
  }
  @media (max-height: $iPad) and (orientation: portrait) {
    width: 250px;
  }
  @media (max-width: $iPad) and (orientation: landscape) {
    width: 550px;
  }
  @media (max-height: $iPhoneSE) and (orientation: portrait) {
    width: 200px !important;
  }
  @media (max-width: $iPhoneSE) and (orientation: landscape) {
    width: 550px;
  }
  @media (max-height: $iPadAir) and (orientation: portrait) {
    width: 300px;
  }
  @media (max-width: $iPadAir) and (orientation: landscape) {
    width: 600px;
  }

}