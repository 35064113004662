.stock-table-container-div {
  // padding: 50px 10px 10px 10px;
}

.stock-table .MuiTableCell-body {
  color: white !important;
  font-size: 1rem !important;
}

.stock-table .MuiTableCell-head {
  font-size: 1.5rem !important;
}

.stock-table
  .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active
  .MuiTableSortLabel-icon {
  color: white;
}

.stock-table .MuiTableSortLabel-root.MuiTableSortLabel-active {
  color: white;
}

.paper-stock-table {
  background-color: #27293d !important;
  color: white !important;
}

.paper-stock-row-table {
  background-color: #27293d !important;
  color: white !important;
  height: 90vh !important;
}

.stock-row-table {
  height: 100% !important;
}

.stock-row-table .MuiTypography-body1 {
  font-size: 0.9em !important;
}

.stock-row-cell {
  border-right: 1px solid white !important;
  font-size: small !important;
  text-align: center !important;
}

.green-background {
  background-color: green !important;
  cursor: pointer;
}

.red-background {
  background-color: maroon !important;
  cursor: pointer;
}

.disabled-background {
  background-color: black !important;
  cursor: not-allowed;
}

.orange-background {
  background-color: orange !important;
  cursor: pointer;
}

#stock-tab-app-bar .MuiTabs-flexContainer {
  display: flex;
  justify-content: center;
  background-color: #27293d !important;
}

.out-of-stock-buttons {
  display: flex;
  position: fixed;
  top: 7px;
  right: 7px;
  z-index: 999;
}
.stock-row-table {
  height: calc(100vh - 158px) !important;
}

#open-all-rows-button {
  position: absolute !important;
  right: 10px !important;
  margin-top: -30px;
}
