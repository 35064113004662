.automatic-email-editor-grid-container {
  height: 100vh !important;
}

.email-editor-container {
  overflow: auto;
  border: 1px solid white;
}

.email-template-div {
  padding: 40px 20px 20px 20px;
}

.cell-padding {
  padding: 10px;
}

.email-template-table {
  background-color: white;
}

.email-template-popup-paper {
  height: auto;
  max-height: 95vh;
  width: 85vw;
  overflow-y: auto;
  background-color: #fefefe;
  margin: 0px;
  padding: 5px !important;
}

.editor-container-row-cell {
  border: 1px solid black;
  height: 100%;
  width: 100%;
}

.editor-container-popup {
  border: 1px solid black;
  height: 100%;
  width: 100%;
}

.editor-style-row-cell {
  font-size: 0.8em;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
}

.editor-style-popup {
  font-size: 1.1em;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .editor-style-row-cell {
    font-size: 1.5em;
  }
  .editor-style-popup {
    font-size: 1.1em;
  }
}
