.customer-payment-card-root {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  background: #ffffff;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.customer-payment-card-title {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 157%;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.87);
}

.payment-methods-container {
}

.selected-payment-container{
  display: flex;
  gap: 30px;
}
.no-selected-payment-container{
  padding-top: 15px;
}
.selected-payment-details{
  display: grid;
  grid-template-columns: 1fr;
  
}
