.appbar-container {
  text-align: center;
}

.main-container {
  margin-left: 250px;
  padding: 30px;
}

#admin-panel {
  .MuiTableCell-head {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .MuiTableCell-body {
    color: rgba(0, 0, 0, 0.87) !important;
  }
}

.admin-layout-menu-nav-menu {
  width: 250px;
  flex-shrink: 0;
  .admin-layout-menu-nav-menu-paper {
    width: 250px;
    background-color: #F4F0E9;
  }
}

@media (max-width: 1500px) {
  .main-container {
    margin-left: 60px;
  }
  .admin-layout-menu-nav-menu {
    width: 60px;
    .admin-layout-menu-nav-menu-paper {
      width: 60px;
      overflow: hidden;
    }
    .admin-layout-menu-nav-item-text {
      display: none;
    }
  }
}
