$iPhoneXR: 896px;
$iPadMini: 1024px;

.control-container {
  padding: 20px;
}

.control-home-button-style {
  width: 100% !important;
  font-weight: bold !important;
  padding: 20px !important;
  font-size: 1.5em !important;
  margin-bottom: 10px !important;
}

.messaging-div {
  padding: 40px 20px 20px 20px;
}

.messaging-table-container {
  // height: -webkit-fill-available !important;
}

.messaging-body-cell {
  color: black !important;
}

.messaging-body-cell-delete {
  text-align: right !important;
  padding-right: 10px !important;
}

.MuiInput-underline::before {
  border-bottom: none !important;
}

.messaging-input>input {
  text-align: center !important;
}

.messaging-input>textarea {
  text-align: center !important;
}

.messaging-fab {
  margin: -25px 0px 0px -10px !important;
}

.messaging-delete-icon {
  color: #f99;
}

.message-table-paper {
  width: 100%;
  // max-height: 50vh;
  // overflow: auto;
}

.message-table-container {
  max-height: 50vh;
}

.message-table-car-container {
  margin: 0px 20px;
}

.message-table-card-greeting-quickfix {
  position: absolute;
  top: 30px;
  left: 194px;
}

.message-table-card-greeting {
  position: absolute;
  top: 60px;
  left: 320px;
}

.message-table-card-grid {
  margin: 10px;
}

.message-table-card-message {
  margin-top: 15px !important;
  white-space: pre-wrap;
}

.message-table-card-signed {
  margin-top: 25px !important;
}

.robot-font {
  font-family: astSpahn !important;
  font-size: 40px !important;
  line-height: unset !important;
  color: blue;
}

.robot-font-quickfix {
  font-family: astSpahn !important;
  font-size: 25px !important;
  line-height: unset !important;
  color: blue;
}

.message-body-id {
  width: fit-content;
}

.message-body-id-edit {
  width: fit-content;
  height: 40px;
  color: blue;
}

#robot-controls-id .MuiDialog-paper {
  min-width: 640px !important;
  padding: 15px !important;
  background-color: black;
  text-align: -webkit-center;

  @media (max-height: $iPhoneXR) and (orientation: portrait) {
    min-width: 375px !important;
    max-width: 375px !important;
  }

  @media (max-width: $iPhoneXR) and (orientation: landscape) {
    min-width: 896px !important;
    max-width: 896px !important;
  }
}

.configurable-output {
  background-color: #fffe70;
  padding: 15px;
  text-align: center;
}

.digital-output {
  background-color: #f2f2f2;
  padding: 15px;
  text-align: center;
}

.robot-controls-title {
  text-align: center;
  color: white;
}

#robot-control-grid .MuiFormGroup-root {
  width: 135px !important;
}

#express-zone-table .MuiTableCell-head {
  color: black !important;
}

#express-zone-table .MuiTableCell-body {
  color: black !important;
  font-size: medium !important;
}

.highlight-light-red {
  background-color: #ffdddd;
}

.output-select-chips {
  display: flex !important;
  flex-wrap: wrap !important;
  font-size: 50px !important;
}

.output-select-chips .MuiChip-root {
  height: 24px !important;
}

.infinity-note-button {
  position: absolute !important;
  top: 0 !important;
  margin-left: 10px !important;
  display: block;
  background-color: white;
}