.products-root {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.products-loading-skeleton-container {
  display: grid;
  grid-template-columns: 1fr;
}
.product-results-container {
  display: grid;
  grid-template-columns: 1fr;
}
.producs-paper-container {
  border-radius: 4px !important;
  border: 1px solid #d9d9d9 !important;
  background: #ffffff !important;
}

.products-actions-container {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  gap: 10px;
}

.products-table-root {
  display: flex;
  gap: 20px;
}
.products-table-sticky-cell {
  position: sticky;
  left: 0;
}

.products-table-sku-cell {
  min-width: 150px;
}

.products-table-other-cell {
  min-width: 45px;
}

.products-table-header {
  color: #00000099;
}

.products-table-inactive-row {
  opacity: 0.3;
  background-color: #e5e5e5 !important;
}
.products-table-even-row {
  background-color: #ffffff;
}
.products-table-odd-row {
  background-color: #fefdfa;
}

.products-table-even-cell {
  background-color: #f6f3e9;
}
.products-table-odd-cell {
  background-color: #eceae1;
}
.products-table-cell {
  border-width: 0px 1px 1px 0px !important;
  border-style: solid;
  border-color: rgba(224, 224, 224, 1);
  .MuiSvgIcon-root {
    font-size: 1.2rem !important;
  }
}

.products-table-header-cell {
  border: 0px !important;
}
.products-table-heading {
  font-size: 18px !important;
  font-weight: 700 !important;
  color: #000000de;
}
.products-table-container-wrapper {
  display: flex;
  flex-direction: column;
}
