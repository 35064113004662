.claims-order-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.edit-claims-order-paper {
  height: 90vh;
  max-height: 95vh;
  width: 95vw;
  overflow-y: auto;
  background-color: #fefefe;
  margin: 0px;
  padding: 5px !important;
}
