.subscriptions-row-outer-cell {
  border-width: 0px !important;
}

.subscriptions-row-inner-table tr:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.5);
  border-width: 0px !important;
}

.subscriptions-row-statusBtn-container {
  text-align: end !important;
  padding: 10px !important;
}

.subscriptions-row-statusBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 0 15px;
}
.subscriptions-row-statusBtn span {
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.2px;
}
.subscriptions-row-statusBtn span {
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.2px;
}

.subscriptions-row-saddressDetailContainer {
  flex-direction: column;
  padding-top: 10px;
}

.subscriptions-row-addressContainer {
  display: flex;
  gap: 20px;
  font-size: 14px;
  @media (max-width: 800px) {
    flex-direction: column;
    gap: 0px;
    margin-right: 0px;
    margin-top: 24px;
  }
}

.subscriptions-row-editLinkContainer {
  bottom: 0;
  transition: all ease-in-out 0.3s;
  display: flex;
}

.subscriptions-row-editLinkContainer span {
  color: #8a8d8f;
  margin-right: 24px;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.4px;
  border-bottom: 1px solid #8a8d8f;
}

.subscriptions-row-paymentDetailContainer {
  display: flex;
  gap: 10px;
  font-size: 12px;
  align-items: center;
}

.subscriptions-row-newProductContainer {
  display: flex;
  justify-content: end;
  gap: 10px;
  font-size: 12px;
  align-items: center;
}

.subscriptions-row-itemContainer {
  display: grid;
  grid-template-columns: 64px 0.6fr 0.2fr 0.2fr;
  gap: 24px;
  padding: 0px 0px 30px 0px;
  align-items: center;
  @media (max-width: 800px) {
    grid-template-columns: 64px 1fr;
    gap: 16px;
  }
}



.subscriptions-row-itemContainer:last-child {
  border-bottom: 1px solid #e5e5e5;
}

.subscriptions-row-imageContainer {
  width: 100%;
  height: 80px;
  cursor: pointer;
}

.subscriptions-row-imageContainer img {
  width: 100%;
  object-fit: cover;
}

.subscriptions-row-itemName {
  font-weight: 500;
  font-size: 16px;
  display: block;
  @media (max-width: 800px) {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
}

.subscriptions-row-orderItemMeta {
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
    justify-content: space-between;
  }
}

.subscriptions-row-meta {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: var(--grey-phillipine);
}

.subscriptions-row-transactionalGrid .subscriptions-row-meta {
  text-align: end;
}
.subscriptions-row-transactionalGrid .subscriptions-row-grandTotal {
  text-align: end;
}

.subscriptions-row-grandTotal {
  display: flex;
  align-items: center;
  font-size: 16px !important;
  line-height: 24px;
}
.subscriptions-row-bold {
  font-weight: 600 !important;
}

.subscriptions-row-mobileActionContainer {
  display: none;
  @media (max-width: 800px) {
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

.subscriptions-row-itemTotal{
  text-align: end;
}

.subscriptions-row-itemTotalMobile {
  display: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  @media (max-width: 800px) {
    display: block;
  }
}

.subscriptions-row-actionContainerMobile {
  display: none;
  @media (max-width: 800px) {
    display: block;
  }
}

.subscriptions-row-addItemToCart {
  font-weight: 600;
  font-size: 9px;
  line-height: 16px;
}

.subscriptions-row-ccDetails{
  display: flex;
  gap: 20px;
}

.subscriptions-row-itemList {
  margin-top: 20px;
  padding: 0px 20px;
  border-top: 1px solid #e5e5e5;
}

.subscriptions-row-closeContainer {
  display: flex;
  justify-content: end;
  border-top: 1px solid #e5e5e5;
  
}
.subscriptions-row-closeContainer:first-child {
  border-top: 0px;
  
}

.subscriptions-row-closeContainer svg{
  width: 15px;
  height: 15px;
}

.subscriptions-row-transactionDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 24px;
}

.subscriptions-row-transactionalGrid {
  display: grid;
  grid-template-columns: 1fr 0.25fr;
  gap: 8px 24px;
}

.subscriptions-row-transactionalGrid > span {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
}


.subscriptions-row-meta {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: var(--grey-phillipine);
}

.subscriptions-row-transactionalGrid .subscriptions-row-meta {
  text-align: end;
}
.subscriptions-row-transactionalGrid .grandTotal {
  text-align: end;
}

.subscriptions-row-grandTotal {
  font-size: 16px !important;
  line-height: 24px;
}
.subscriptions-row-bold {
  font-weight: 600 !important;
}

.subscriptions-row-actions {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.subscriptions-row-processnow {
  justify-self: end;
  display: flex;
  justify-content: flex-end;
}
.subscriptions-row-processnow button{
  background-color: #0d662d;
}
.subscriptions-row-date-cell{
  display: flex;
  justify-content: flex-start;
  gap: 30px;
}