.handwritten-card-container {
  position: relative;
  color: black;
}

.card-greeting-edit {
  position: absolute;
  top: 50px;
  left: 320px;
}

.card-grid {
  //   width: 100% !important;
  margin: 10px;
}

.card-message {
    margin-top: 15px !important;
    white-space: pre-wrap;
}

.card-signed {
    margin-top: 25px !important;
}

.green-textfield div fieldset {
  border-color: #009900 !important;
}

.green-textfield label {
  color: #009900 !important;
}

.robot-font-edit {
  font-family: astSpahn !important;
  font-size: 40px !important;
  line-height: unset !important;
  color: blue;
}

.hyperlink {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

#edit-popup-item-table tr:nth-child(even) 
{
  background-color: #f2f2f2;
}

#edit-popup-item-table .MuiTableCell-head
{
  color: black !important;
}

#edit-popup-item-table .MuiTableCell-body
{
  color: black !important;
  font-size: medium !important;
}

.edit-flavour-label {
  font-weight: bold !important;
  font-size: 1.8em !important;
}

.ignore-address-button {
  padding: 2px 5px !important;
  font-size: 0.75rem !important;
}

.small-header {
  width : 20%;
}

// .compostable-label {
//   color: rgb(133, 112, 86) !important
// }

// .plastic-label {
//   color: black !important
// }

// .misc-label {
//   color: grey !important
// }