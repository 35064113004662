.root {
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  gap: 30px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.create-order-layout{
  display: grid;
  grid-template-columns: 1fr 0.2fr;
  gap: 40px;
}

.create-order-grid {
  //   max-width: 70% !important;
  //   margin: 0px 15% !important;
}

.product-tab-header {
  color: #862121 !important;
  background-color: #faf8f3 !important;
  font-weight: 700 !important;
  size: 12px !important;
}

.product-tab-appbar {
  box-shadow: none !important;
}

.product-tab-panel {
  background-color: #faf8f3 !important;
}

.product-table {
  margin: 20px !important;
  max-height: 500px !important;
  overflow: auto;
}
.product-table-head td {
  font-weight: 700 !important;
  font-size: 14px !important;
}

.product-table-body td {
  font-size: 14px !important;
}

.product-table-row-title-cell {
  max-width: 50px !important;
}

.quantity-button {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  float: center !important;
}

.product-description-container {
  display: flex !important;
  align-items: center !important;
}

.create-order-button-container {
  display: flex;
  justify-content: flex-end;
}

.create-order-button-container button {
  margin-left: 10px !important;
}

.product-image {
  max-width: 100%;
  max-height: 100%;
}

.product-image-container {
  width: 60px;
  height: 60px;
}

.order-confirmation-dialog .MuiTableCell-body {
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: medium !important;
}

.order-confirmation-dialog .MuiTableCell-head {
  color: rgba(0, 0, 0, 0.87) !important;
}

.order-confirmation-dialog td {
  margin: 10px !important;
}

.quantity-button-text-field {
  width: 80px !important;
}

.quantity-button-text-field .MuiOutlinedInput-root {
  height: 30px !important;
}

.quantity-button-input-field-highlight {
  text-align: center;
  background-color: #862121 !important;
  color: #fff !important;
}

.quantity-button-input-field {
  text-align: center;
  height: 30px !important;
}

.quantity-button-button-group {
  height: 30px !important;
}

.quantity-button-text-field .MuiOutlinedInput-input {
  padding: 6px 0px 7px !important;
}

.customer-details-cards{
  display: flex;
  flex-direction: column;
  gap: 25px;
}
