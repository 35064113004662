@font-face {
  font-family: "astSpahn";
  src: local("astSpahn"), url("./Font/astSpahn.ttf") format("truetype");
}

body {
  background-color: black;
  margin: unset !important;
  overflow: hidden;
}

.home-icon {
  color: white !important;
  position: absolute !important;
  z-index: 99;
  top: 0px !important;
  left: 0px !important;
  width: 40px !important;
  height: 40px !important;
  background-color: black !important;
  border-radius: 50% !important;
  padding: 5px !important;
}

.home-icon:hover {
  cursor: pointer;
  background-color: white;
  color: black;
}

.login-style {
  height: 100vh;
  width: 100vw;
  text-align: center;
}

.google-signin {
  background-color: white !important;
  height: 50px;
  width: fit-content;
  top: 50%;
}

.packman-loader {
  position: fixed !important;
  z-index: 999;
  top: 50%;
  left: 45%;
}

.default-admin-table-root {
  display: flex;
  gap: 20px;
}

.default-admin-table-header {
  color: #00000099;
}
.default-admin-table-even-row {
  background-color: #ffffff;
}
.default-admin-table-odd-row {
  background-color: #fefdfa;
}

.default-admin-table-even-cell {
  background-color: #f6f3e9;
}
.default-admin-table-odd-cell {
  background-color: #eceae1;
}
.default-admin-table-cell {
  border-width: 0px 1px 1px 0px !important;
  border-style: solid;
  border-color: rgba(224, 224, 224, 1);
}

.default-admin-table-header-cell {
  border: 0px !important;
}
.default-admin-table-heading {
  font-size: 18px !important;
  font-weight: 700 !important;
  color: #000000de;
}
.default-admin-table-container-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  .MuiTableCell-head {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .MuiTableCell-body {
    color: rgba(0, 0, 0, 0.87) !important;
  }
}

.drag-drop-icon {
  color: #cfcfcf !important;
  &:hover {
    color: #3b3e66 !important;
  }
}