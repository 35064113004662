.root {
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 51;
}

.backdrop {
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.7;
    cursor: pointer;
}

.hide {
    opacity: 0;
    visibility: hidden;
}

.show {
    opacity: 1;
    visibility: visible;
}

.contentContainer {
    border-radius: 4px;
    overflow: hidden;
    background-color: #FFFFFF;
    z-index: 53;

    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -70%);
}

@media (max-width: 550px) {
    .contentContainer {
        width: 90vw;
        top: 67vh;
    }
}