$iPhoneXR: 812px;
$iPad: 1024px;
$iPadPro: 1366px;
$camistry-grey-one: #fafafa;

.home-container {
  height: 100vh;
  background-color: $camistry-grey-one !important;
  @media (max-width: $iPhoneXR) and (orientation: landscape) {
    padding-top: 1% !important;
  }
}

.home-button-grid-container {
  background-color: black;
  height: 100%;
  padding: 10px;
}

.grid-items {
  padding: 10px;
}

.home-card-for-button {
  height: 100% !important;
}

.home-button {
  color: linear-gradient(0deg, #3358f4, #1d8cf8) !important;
  // color: green !important;
  //   position: absolute !important;
  align-self: center !important;
  font-size: 2em !important;
  height: 100% !important;
  width: 100% !important;
  background-color: #27293d !important;
  @media (max-height: $iPhoneXR) and (orientation: portrait) {
    font-size: 1.5em !important;
  }
  @media (max-height: $iPhoneXR) and (orientation: landscape) {
    font-size: 1.5em !important;
  }
  @media (max-height: $iPad) and (orientation: landscape) {
    font-size: 2em !important;
  }

  &:hover {
    background-color: #b1b4cd !important;
    color: #27293d !important;
}
}

.home-button span {
  font-size: 0.8em !important;
}

.color-black{
  .MuiTableCell-head{
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .MuiTableCell-body{
    color: rgba(0, 0, 0, 0.87) !important;
  }
}

.text-center {
  text-align: center;
}

.quickfix-dialog-title {
  background: rgb(39, 41, 61);
  color: white;
}

.home-num-items-container {
  margin-left: 10%;
}