$iPhoneXR: 896px;
$iPadMini: 1024px;

.footer1 {
  position: absolute;
  bottom: 50px;
  left: 0px;
  color: white;
  height: 50px;
  width: 100vw;
  background-color: #1a1a1a;
}

.footer2 {
  position: absolute;
  bottom: 0px;
  left: 0px;
  color: white;
  height: 50px;
  width: 100vw;
  background-color: #1a1a1a;
}

.footer-grid-container {
  height: inherit;
  padding: 0px 10px 0px 10px;
}

.footer-grid-items {
  align-items: center;
  justify-content: flex-end;
  height: inherit;
  display: flex;
  max-width: unset !important;
  flex-basis: unset !important;
}

.footer-grid-items-typography {
  @media (max-height: $iPhoneXR) and (orientation: portrait) {
    font-size: 13px !important;
  }
  @media (max-width: $iPhoneXR) and (orientation: landscape) {
    font-size: 20px !important;
  }
}

.footer-grid-items-typography-line-delay {
  @media (max-height: $iPhoneXR) and (orientation: portrait) {
    font-size: 11px !important;
  }
  @media (max-width: $iPhoneXR) and (orientation: landscape) {
    font-size: 20px !important;
  }
}

.footer-grid-clock {
  align-items: center;
  justify-content: left;
  height: inherit;
  display: flex;
}

.footer-grid-items-home {
  align-items: flex-end;
  justify-content: center;
  height: inherit;
  display: flex;
}

.switch-root {
  width: 42px !important;
  height: 26px !important;
  padding: 0px !important;
  margin: 8px !important;

  @media (max-height: $iPhoneXR) and (orientation: portrait) {
    width: 26px !important;
    height: 16px !important;
    padding: 0px !important;
    margin: 4px !important;
  }
  @media (max-width: $iPhoneXR) and (orientation: landscape) {
    width: 35px !important;
    height: 20px !important;
    padding: 0px !important;
    margin: 8px !important;
  }
}

.switch-switchbase {
  padding: 1px !important;
  &.Mui-checked {
    transform: translateX(16px) !important;
    color: white !important;
    & + .MuiSwitch-track {
      background-color: #006600 !important;
      //   background: linear-gradient(to bottom, #99ffcc, #99ffcc,  #99ffcc, #eee, #99ffcc, #99ffcc, #99ffcc) !important;
    }
    @media (max-height: $iPhoneXR) and (orientation: portrait) {
      transform: translateX(11px) !important;
    }
    @media (max-width: $iPhoneXR) and (orientation: landscape) {
      transform: translateX(15px) !important;
    }
  }
}

.switch-track {
  border-radius: 13px !important;
  background-color: #002b80 !important;
  //   background: linear-gradient(to bottom, #ff9999, #ff9999, #ff9999, #eee, #ff9999, #ff9999, #ff9999);
  opacity: 1 !important;
  border: none !important;
}

.switch-thumb {
  width: 24px !important;
  height: 24px !important;
  @media (max-height: $iPhoneXR) and (orientation: portrait) {
    width: 14px !important;
    height: 14px !important;
  }
  @media (max-width: $iPhoneXR) and (orientation: landscape) {
    width: 18px !important;
    height: 18px !important;
  }
}

.clock-font {
  font-size: 25px !important;
  @media (max-height: $iPhoneXR) and (orientation: portrait) {
    font-size: 14px !important;
  }
  @media (max-width: $iPhoneXR) and (orientation: landscape) {
    font-size: 20px !important;
  }
}

.MuiFormControlLabel-root {
  margin-left: unset !important;
  margin-right: unset !important;
}

// .checkbox-autopilot-true > .MuiIconButton-label > .MuiSvgIcon-root {
//   color: #006600 !important;
// }

// .checkbox-autopilot-false > .MuiIconButton-label > .MuiSvgIcon-root {
//   color: #002b80 !important;
// }

.button-status-mobile {
  @media (max-height: $iPadMini) and (orientation: portrait) {
    display: none !important;
  }
}

.autopilot-checkoxes {
  // width: 88px !important;
  width: 150px !important;
  @media (max-height: $iPhoneXR) and (orientation: portrait) {
    display: none !important;
  }
}

.force-pause-green {
  color: #006600;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
}

.force-pause-blue {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
}

.robot-stock-ok {
  background-color: #006600 !important;
}

.play-stop-button {
  width: 100px;
}

.play-stop-button-mobile {
  //width: 50px;
  padding: 0;
  min-width: unset !important;
  margin: 0 !important;
}

.play-stop-button-mobile > span > span {
  margin: 0 !important;
}
