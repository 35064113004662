.customer-notes-root {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
}

.customer-notes-input-wrapper {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 0.2fr;
}

.customer-notes-line {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  padding: 10px;
  border-bottom: 1px solid #dcdcdc;
}
