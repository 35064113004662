.customer-details-root {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 30px;
}

.side-cards-container {
  display: grid;
  grid-template-columns: 2fr;
  gap: 30px;
  height: fit-content;
}

.customer-container {
  display: grid;
  grid-template-columns: 2fr;
  gap: 50px;
}
.customer-personal-details {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
}
.customer-details-header-container {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
}

.customer-orders-header{
  display: flex;
  justify-content: space-between;
}


.action-container {
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  width: 100%;
}
.email-container {
  display: flex;
  gap: 15px;
}

.meta {
  color: #8a8d8f;
}

.icon-container {
  display: flex;
  gap: 10px;
}

.icon-span {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px;
}

.quick-links-container {
  display: flex;
}

.customer-details-container {
  display: flex;
}
