.root {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
}
.header-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.name-container {
  width: 100%;
}
