#stock-instrucitons-table .MuiTableCell-body {
    font-size: 1.5em !important;
}

#stock-instrucitons-table .MuiTableCell-head {
    font-size: 1.2em !important;
}

#minimum-row-container {
    display: flex;
    justify-content: center;
    margin: 10px;
}

#minimum-row-container label, #minimum-row-container input, #minimum-row-container svg {
    color: white;
    font-size: 1.2em;
    text-align: center;
}

#minimum-row-container .MuiSelect-root {
    color: white !important;
    width: 220px;
    font-size: 1.2em;;
    text-align: center;
}