#postal-map-delayed-table .MuiTableCell-body {
    color: rgba(0, 0, 0, 0.87) !important;
    font-size: medium !important;
    padding: 5px !important;
}

#postal-map-delayed-table .MuiTableCell-head {
    color: rgba(0, 0, 0, 0.87) !important;
    font-size: medium !important;
}

#zone-table {
    padding-top: 10px;
    padding-bottom: 10px;
}

#zone-table .MuiTableCell-body {
    color: rgba(0, 0, 0, 0.87) !important;
    font-size: medium !important;
    padding: 5px !important;
}

#zone-table .MuiTableCell-head {
    color: rgba(0, 0, 0, 0.87) !important;
    font-size: medium !important;
}
.zone-edit-save-button {
    max-height: 30px;
    margin-top: 10px;
}

.zone-button {
    width: 100px;
}  

.zone-button-container {
    position: absolute;
    z-index: 1200;
    left: 50%;
    margin-left: -50px;//half .zone-button width
    margin-top: 10px;
}

.button-float-right {
    float: right;
    margin: 5px !important;
}