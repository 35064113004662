.add-new-card-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.payment-container-stripe {
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
}
