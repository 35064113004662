
.order-summary-wrapper{
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}
.order-summary-item{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dcdcdc;
}
.order-summary-custom-total{
  padding-bottom: 9px;
}  
.create-order-discount-code-container {
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
